import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import {GainLotoComponent} from './views/services/gain-loto/gain-loto.component';

const adminRoutes: Routes = [
    {
      path: 'home',
      loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule)
    },
    {
      path: 'services',
      loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule)
    },
    {
      path: 'cartes',
      loadChildren: () => import('./views/cartes/cartes.module').then(m => m.CartesModule)
    },
    {
      path: 'transactions',
      loadChildren: () => import('./views/transactions/transactions.module').then(m => m.TransactionsModule)
    },
    {
      path: 'reporting',
      loadChildren: () => import('./views/reporting/reporting.module').then(m => m.ReportingModule)
    },
    {
      path: 'clients',
      loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule)
    },
    {
      path: 'refunds',
      loadChildren: () => import('./views/refunds/refunds.module').then(m => m.RefundsModule)
    }
   
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarCompactComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
