// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const BASE_API = 'https://services.pdg-dev.com';
export const environment = {
  production: false,
  name: 'nigerprod',
  walletApi: 'https://wallet-services.nt-albey.ne/api',
  mauritanieApi : BASE_API + '/mauritanie_services/api',
  apiDgpsn: BASE_API + '/dgpsn_services/api',
  apiAuthUrl:  'https://auth-services.nt-albey.ne/api',
  apiAdminUrl:'https://admin-services.nt-albey.ne/api',
  apiTransfertUrl: 'https://transfert-services.nt-albey.ne/api',
  apiReseauUrl: 'https://reseau-services.nt-albey.ne/api',
  apiBourseE: BASE_API + '/digimp_services/api',
  apiPas: BASE_API + '/pasm_services/api',
  apiDgse: BASE_API + '/dgse_services/api',
  apiAssurance: BASE_API + '/insurance_services/api',
  urlFront:'https://caisse.nt-albey.ne/#/sessions/signin',
  urlFrontstandard:'https://pdg.sn/pos/#/sessions/signin',
  urlFrontforotNT:'https://caisse.nt-albey.ne/#/sessions/forgot',
  urlFrontfirstNT:'https://caisse.nt-albey.ne/#/sessions/updatePwd',
  urlFrontforot:'https://pdg.sn/pos/#/sessions/forgot',
  urlFrontfirst:'https://pdg.sn/pos/#/sessions/updatePwd',
// https://mass-payment-services.niger-telecom.tech/
  // https://osticket-services.niger-telecom.tech/
  //   https://partners-services.niger-telecom.tech
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
