import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { HttpClient} from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { User } from "../models/user";
import { BehaviorSubject } from 'rxjs';
import { Entite } from "../models/Entite";
import { Constantes } from "../constantes";
import { isObject } from "util";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = false;
  private entitySource = new BehaviorSubject(new Entite({}));
  currentEntity = this.entitySource.asObservable();

  constructor(private store: LocalStoreService, private router: Router, public http:HttpClient) {
    this.checkAuth();
  }

  changeEntite(entite:Entite){
    this.entitySource.next(entite);
  }

  checkAuth() {
    this.authenticated = this.store.getItem("guichet_login_status");
  }

  getUser():User {
    if(isObject(this.store.getItem('guichet_current_user'))){
      return new User(this.store.getItem('guichet_current_user'));
    }
    else{
      this.signout();
    }
    
  }
  
  getCurrentUserEntite(codeEntite) {
    return this.http.get<Entite>(`${environment.apiAdminUrl}/entites?codeEntite=${codeEntite}`)
  }
  
  signin(credentials) {
    return this.http.post<any>(`${environment.apiAuthUrl}/login`, credentials);
  }
  validerCode(credentials) {
    return this.http.post<any>(`${environment.apiAuthUrl}/otp/verify`, credentials);
  }
  saveLoggedUser(data) {
    this.store.setItem("guichet_jwt_token", data.token);
    this.store.setItem("guichet_current_user", new User(data.user));
    this.store.setItem("guichet_login_status", true);
  }
  auth(){
  this.authenticated = true;
}
  signout() {
    this.authenticated = false;
    this.store.setItem("guichet_login_status", false);
    this.store.setItem("guichet_jwt_token", null);
    this.store.setItem("guichet_current_user", null);
    this.store.clear();
    this.router.navigateByUrl("/sessions/signin");
  }
  fogotPwd(credentials) {

    return this.http.post<any>(environment.apiAuthUrl + Constantes.FOGOT_PASSWORD, credentials);
  }
  getJwtToken() {
    return this.store.getItem('guichet_jwt_token');
  }
   updatePwd(credentials) {
    return this.http.put<any>(environment.apiAuthUrl  + '/users/password/change', credentials);
  }
  updatePin(credentials) {
    return this.http.put<any>(environment.apiAuthUrl  + '/users/pin/change', credentials);
  }
  getMessage(page = 1,query){
    return this.http.get<any[]>(`${environment.apiReseauUrl}/messages?_page=${page}${query}`);
  }
  getMessageEncours(page = 1,query){
    return this.http.get<any[]>(`${environment.apiReseauUrl}/messages/encours${query}`);
  }
  async getIpAdress(){
    return await this.http.get<any>('https://api.ipify.org/?format=json').toPromise()
  }
 async getPaysByIP(lat,lgt){
    // let ip;
    // ip = await this.getIpAdress();
    // console.log('iip2',ip)
   //const ip = '5.189.173.32';
   //return await this.http.get<any>('http://www.geoplugin.net/json.gp?ip='+ip.ip).toPromise();
   return await this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lgt}&key=AIzaSyBbK3IWS-p-bNFKj0jd1OtX2AJWVPXKHhU`).toPromise();
  }
}
