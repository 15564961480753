
export class Constantes {
  static HYDRA_MEMBER = 'hydra:member';
  static HYDRA_DESCRIPTION =  'hydra:description'
  static HYDRA_TOTAL_ITEMS = 'hydra:totalItems'
  static DGC_JWT_TOKEN = 'dgc_jwt_token';
  static DGC_CURRENT_USER = 'dgc_current_user';
  static LOGIN_STATUS = 'login_status';
  static DEVISE = 'XOF';
  static CODE_AIRTIME = 'airtime';
  static CODE_SERVICE_PAIEMENT = 'encaissement';
  static TRANSFERT_SERVICE_CODE = 'BGTRANSFERT_ENVOI';
  //static TRANSFERT_SERVICE_CODE = 'BGTRANSFERT';
  static NIGER_BGMOB_OFFERS = 'BGMOB_OFFERS';
  static TRANSFERT_SERVICE_CODER = 'BGTRANSFERT_RETRAIT';
  static WALLETCASHIN_SERVICE_CODE = 'BGWALLET_CASHIN';
  static WALLETCASHOUT_SERVICE_CODE = 'BGWALLET_CASHOUT';
  static AIRTIME_SERVICE_CODE = 'BGAIRTIME';
  static PAIEMENT_MASSE ='BGMULTIPAY';
  static ACHAT_SERVICE_CODE = 'BGACHAT';
  static Ticket_SERVICE_CODE = 'BGTICKET';
  static TRANSFERT_INT_SERVICE_CODE = 'BG_TRSF_INT';
  static BGENC = 'BGENC';
  static ASSURANCE='BGINSURANCE';
  static SERVICE_CARTE='BGCARTE';
  static SERVICE_FORFAIT='BGMOB_OFFERS';
  static FRAIS_A_PART = 'frais à part';
  static UPDATE_PASSWORD = '/users/password/change';
  static FOGOT_PASSWORD = '/users/password/forget';
  static BGRECHARGE = 'BGRECHARGE';
  static BGABO = 'BGAO';
  static VOYAGE = 'Voyage';
  static MRH = 'MRH';
  static AUTO = 'Auto';
}
